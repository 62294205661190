<template>
  <div class="bg-login">
    <div class="top-bar">
      <img src="../../assets/logo-login.png" style="float:left;margin:7px 15px;height:56px;" />
    </div>
    <div class="login-container">
      <el-form ref="loginForm" :model="form" :rules="rules" label-position="left" class="login-box">
        <div class="title-container">
          <h3>欢迎使用</h3>
          <p>密码登录</p>
        </div>
        <el-form-item prop="sysAccount" style="margin-bottom:10px;">
          <el-input type="text" size="mini" placeholder="请输入账号" v-model="form.sysAccount" maxlength="11" @keyup.enter.native="subLogin" />
        </el-form-item>
        <el-form-item prop="sysPwd" style="margin-bottom:30px;">
          <el-input type="password" size="mini" placeholder="请输入密码" v-model="form.sysPwd" @keyup.enter.native="subLogin" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="width:100%;margin-bottom:30px;" :disabled="!form.sysAccount || !form.sysPwd" v-debounce="subLogin">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="bottom-bar">
      <p>Copyright © 2021 共幸科技（深圳）有限公司 400-6310-818</p>
    </div>
    <ChangePassword :show.sync="showChange" :sysId="userInfo.sysId"></ChangePassword>
  </div>
</template>
<script>
import moment from 'moment'
import { getCookies } from '@/utils/utils'
import { Login } from '@/api/user'
import ChangePassword from '@/components/ChangePassword.vue'
export default {
  name: 'login',
  components: { ChangePassword },
  data() {
    return {
      form: {
        sysAccount: '',
        sysPwd: '',
      },
      islogin: true,           //是否允许登录
      verfThrottling: false,   //验证码节流控制
      imageCode: '',           //验证码图片
      rules: {
        sysAccount: [
          { required: true, message: '账号不可为空', trigger: 'blur' }
        ],
        sysPwd: [
          { required: true, message: '密码不可为空', trigger: 'blur' }
        ]
      },
      showChange: false,
      userInfo: {}
    }
  },
  created() {
    if (getCookies('x-token')) {
      this.$router.push({ path: '/index' });
    }
  },
  methods: {
    subLogin() {
      let form = JSON.parse(JSON.stringify(this.form));
      if (!this.$validate('loginForm')) return
      Login(form).then((response) => {

        if (response.code == 200) {
          if (response.data.roleId == 0 || response.data.organizationId) {
            if (!response.data.pwdUpdateTime || moment().subtract(30, 'days').isSameOrAfter(response.data.pwdUpdateTime)) {//30天强制修改密码
              this.userInfo = response.data || {}
              this.showChange = true
              return
            }
            this.$store.dispatch('setCookiesStorage', response.data)
            console.log(response.data)
            let path = response.data.sysMenus[0].path ? response.data.sysMenus[0].path : response.data.sysMenus[0].child[0].path
            this.$router.push({ path: path });
          } else {
            this.$message.error({
              title: '错误',
              message: '该账号暂未关联机构,请联系管理员关联机构后再开始使用',
              duration: 3500
            });
          }
        } else {
          this.$message.error({
            title: '错误',
            message: response.msg,
            duration: 3500
          });
        }
      })
    },
  }

}
</script>
<style scoped>
.top-bar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  height: 70px;
}
.bottom-bar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  height: 60px;
  text-align: center;
}
html,
body,
.bg-login {
  height: 100%;
}
.bg-login {
  background: url("../../assets/loginbj.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
}
.login-container {
  position: absolute;
  width: 350px;
  height: 380px;
  padding: 20px 40px 0;
  background: #fff;

  box-sizing: border-box;
  top: 45%;
  right: 0%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
}
.title-container h3 {
  text-align: left;
  font-size: 24px;
  margin: 20px 0px;
}
.title-container p {
  text-align: left;
  font-size: 16px;
  margin: 0px 0px 10px 0px;
}
.captcha_code {
  padding: 0px;
  margin: 0 10px;
  height: 38px;
  /* border:1px solid #eee; */
  display: flex;
  align-items: center;
  max-width: 100px;
  float: right;
}
.captcha_code img {
  height: 100%;
}
</style>